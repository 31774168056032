import React from "react";
import { Link } from "react-router-dom";
import scissors from "./assets/scissors.svg";
import nails from "./assets/nails.svg";

function Values() {
  return (
    <section className="section bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-center p-4">
            <img src={scissors} alt="scissors" height="42px" className="mb-3" />
            <h3 className="title">Coiffure Mixte</h3>
            <p>Nous prenons soin de vos cheveux, de la racine aux pointes.</p>
            <Link to="/coiffure" className="btn btn-danger">
              Voir les tarifs
            </Link>
          </div>
          <div className="col-md-6 text-center p-4">
            <img src={nails} alt="nails" height="42px" className="mb-3" />
            <h3 className="title">Onglerie</h3>
            <p>Nous nous occupons de vos ongles.</p>
            <Link to="/onglerie" className="btn btn-danger">
              Voir les tarifs
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Values;
