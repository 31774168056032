import React from "react";

const Coiffure = () => {
  return (
    <>
      <section className="section bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center p-4">
              <h3 className="title">Coiffure Mixte</h3>
              <p>Nous prenons soin de vos cheveux, de la racine aux pointes.</p>
							<br />
							<a
                  href="https://booking.wavy.pro/borealyss"
                  target="_blank"
									rel="noopener noreferrer"
									className="btn btn-danger px-3 py-2"
                >
                  Prendre rdv en ligne
                </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container text-white my-4">
          <div className="row">
            <div className="col-md-6 text-center p-4">
              <h3 className="title">Première visite</h3>
              <p>
                5€ de réduction sur votre première visite pour vous aider à
                découvrir le salon (tarifs adultes uniquement).
              </p>
            </div>
            <div className="col-md-6 text-center p-4">
              <h3 className="title">Étudiant·e</h3>
              <p>
                -10% de réduction sur présentation de votre carte étudiante sur
                l'ensemble des prestations.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-light p-4">
        <div className="container">
          <div className="row text-center title mb-4">
            <div className="col">
              <h1>Tarifs Coiffure Femme</h1>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-xs-2 col-md-2 title-vertical title-vertical-in title text-center">
              <h1>femme</h1>
            </div>
            <div className="col-xs-6 col-md-6">
              <div className="tarif">
                Shampoing brushing - courts / mi-longs / longs
                <p>19€ / 22€ / 25€</p>
              </div>
              <div className="tarif">
                Shampoing coupe brushing - courts / mi-longs / longs{" "}
                <p>34€ / 38€ / 42€</p>
              </div>
              <div className="tarif">
                Shampoing coupe séchage<p>31€</p>
              </div>
              <div className="tarif">
                Coloration <p>27€ / 30€ bio</p>
              </div>
              <div className="tarif">
                Patine - courts / mi-longs / longs<p>12€ / 14€ / 17€</p>
              </div>
              <div className="tarif">
                Permanente séchage - courts / mi-longs / longs <p>59€ / 64€ / 69€</p>
              </div>
              <div className="tarif">
                Permanente coupe séchage - courts / mi-longs / longs <p>71€ / 76€ / 81€</p>
              </div>
              <div className="tarif">
                Mèches brushing - courts / mi-longs / longs <p>58€ / 67€ / 75€</p>
              </div>
              <div className="tarif">
                Mèches coupe brushing - courts / mi-longs / longs <p>73€ / 82€ / 92€</p>
              </div>
              <div className="tarif">
                Lissage brésilien<p>à partir de 150€</p>
              </div>
              <div className="tarif">
                Couleur brushing - courts / mi-longs / longs <p>46€ / 49€ / 52€</p>
              </div>
              <div className="tarif">
                Couleur coupe brushing - courts / mi-longs / longs <p>61€ / 65€ / 69€</p>
              </div>
              <div className="tarif">
                Soin Genoma - courts / mi-longs / longs <p>60€ / 80€ / 100€</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section text-white p-4">
        <div className="container">
          <div className="row text-center title mb-4">
            <div className="col">
              <h1>Tarifs Coiffure Homme</h1>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-xs-2 col-md-2 title-vertical title-vertical-in title text-center">
              <h1>homme</h1>
            </div>
            <div className="col-xs-6 col-md-6">
              <div className="tarif">
                Shampoing Coupe<p>19€</p>
              </div>
              <div className="tarif">
                Coupe sans shampoing <p>13€</p>
              </div>
            </div>
          </div>
          <div className="hide-on-desktop">
            <hr />
          </div>
          <div className="row justify-content-md-center">
            <div className="col-xs-2 col-md-2 title-vertical title-vertical-in title text-center">
              <h1>barbe</h1>
            </div>
            <div className="col-xs-6 col-md-6">
              <div className="hide-on-mobile">
                <hr />
              </div>
              <div className="tarif">
                Tondeuse <p>12€</p>
              </div>
              <div className="tarif">
                Façon Barbier <p>22€</p>
              </div>
              <div className="tarif">
                Tondeuse barbe et rasage à l'ancienne <p>10€ / 12€ / 20€</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-light p-4">
        <div className="container">
          <div className="row text-center title mb-4">
            <div className="col">
              <h1>Tarifs Coiffure Jeune</h1>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-xs-2 col-md-2 title-vertical title-vertical-in title text-center">
              <h1>femme</h1>
            </div>
            <div className="col-xs-6 col-md-6">
              <div className="tarif">
                - 10 ans<p>14€</p>
              </div>
              <div className="tarif">
                10 - 15 ans <p>29€</p>
              </div>
            </div>
          </div>
          <div className="hide-on-desktop">
            <hr />
          </div>
          <div className="row justify-content-md-center">
            <div className="col-xs-2 col-md-2 title-vertical title-vertical-in title text-center">
              <h1>homme</h1>
            </div>
            <div className="col-xs-6 col-md-6">
              <div className="hide-on-mobile">
                <hr />
              </div>
              <div className="tarif">
                - 10 ans<p>12€</p>
              </div>
              <div className="tarif">
                10 - 15 ans <p>15€</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Coiffure;
