import React from "react";

const Onglerie = () => {
  return (
    <>
      <section className="section bg-light p-4">
        <div className="container">
          <div className="row text-center mb-4">
            <div className="col">
              <h1 className="title">Tarifs Onglerie</h1>
							<br />
							<a
                  href="https://booking.wavy.pro/borealyss"
                  target="_blank"
									rel="noopener noreferrer"
									className="btn btn-danger px-3 py-2"
                >
                  Prendre rdv en ligne
                </a>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-xs-2 col-md-2 title-vertical title-vertical-in title text-center">
              <h1>mains</h1>
            </div>
            <div className="col-xs-6 col-md-6">
              <div className="tarif">
                Vernis semi-permanent<p>29€</p>
              </div>
              <div className="tarif">
                Pose de chablon - sans déco / avec déco<p>45€ / 50€</p>
              </div>
              <div className="tarif">
                Remplissage - sans déco / avec déco<p>40€ / 45€</p>
              </div>
            </div>
          </div>
          <div className="hide-on-desktop">
            <hr />
          </div>

          <div className="row justify-content-md-center">
            <div className="col-xs-2 col-md-2 title-vertical title-vertical-in title text-center">
              <h1>pieds</h1>
            </div>
            <div className="col-xs-6 col-md-6">
              <div className="hide-on-mobile">
                <hr />
              </div>

              <div className="tarif">
                Vernis semi-permanent<p>29€</p>
              </div>
            </div>
          </div>
          <div className="hide-on-desktop">
            <hr />
          </div>

          <div className="row justify-content-md-center">
            <div className="col-xs-2 col-md-2 title-vertical title-vertical-in title text-center">
              <h1>autre</h1>
            </div>
            <div className="col-xs-6 col-md-6">
              <div className="hide-on-mobile">
                <hr />
              </div>

              <div className="tarif">
                Dépose<p>15 à 20€</p>
              </div>
              <div className="tarif">
                Ongle cassé<p>4€</p>
              </div>
              <div className="tarif">
                Déco - motif / strass<p>0.50€</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Onglerie;
