import React from "react";
import phone from "./assets/phone.svg";

function Footer() {
  return (
    <footer className="footer m-4">
      <br />
      <br />
      <div className="container">
        <div className="row">
          <div className="col-md-4 footer-box p-3">
            <h3 className="title">Où nous trouver</h3>
            <p className="address m-4">
              Centre commercial la Fauconnière <br />
              64 rue de la Fauconnière <br />
              38170 Seyssinet-Pariset
            </p>
            <p className="tel m-4">
              <img src={phone} alt="phone icon" height="16px" /> {" "}
							<a href="tel:0476095304" className="link">04 76 09 53 04</a>
            </p>
          </div>
          <div className="col-md-4 footer-box p-3">
            <h3 className="title">Horaires</h3>
            <table className="table-center">
              <tbody>
                <tr>
                  <td>Mardi</td>
                  <td className="pl-3">9h - 19h</td>
                </tr>
                <tr>
                  <td>Mercredi</td>
                  <td className="pl-3">9h - 19h</td>
                </tr>
                <tr>
                  <td>Jeudi</td>
                  <td className="pl-3">9h - 19h</td>
                </tr>
                <tr>
                  <td>Vendredi</td>
                  <td className="pl-3">9h - 19h</td>
                </tr>
                <tr>
                  <td>Samedi</td>
                  <td className="pl-3">9h - 17h</td>
                </tr>
                <tr>
                  <td>Dimanche</td>
                  <td className="pl-3">fermé</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-4 footer-box p-3">
            <h3 className="title">Crédits</h3>
            <p className="m-4">
              Copyright &copy; 2024 Tous droits réservés
              <br />
              Template du site réalisé par{" "}
              <a href="https://colorlib.com" target="_blank" rel="noopener noreferrer" className="link">
                Colorlib
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
