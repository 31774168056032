import React from "react";

const Contact = () => {
  return (
    <section className="section bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center p-4">
            <h3 className="title">Nous contacter</h3>
            <p>Passez nous voir !</p>
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2812.5699606631283!2d5.6911034159848!3d45.17555747909861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478af342115f01ed%3A0xa02060b929bfb1c!2sBorealyss!5e0!3m2!1sfr!2sfr!4v1602884306004!5m2!1sfr!2sfr" width="600" height="300" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0" title="map"></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
