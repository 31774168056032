import React from "react";
import { Link } from "react-router-dom";
import logo from "./assets/logo_blanc.png";

const Navigation = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="small logo" width="42px" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbar">
            <ul className="navbar-nav pl-md-5">
              <li className="nav-item" data-toggle="collapse" data-target="#navbar">
                <Link to="/coiffure" className="nav-link">
                  Tarifs Coiffure Mixte
                </Link>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbar">
                <Link to="/onglerie" className="nav-link">
                  Tarifs Onglerie
                </Link>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbar">
                <Link to="/contact" className="nav-link">
                  Nous contacter
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto text-center">
              <li>
                <a
                  href="https://booking.wavy.pro/borealyss"
                  target="_blank"
									rel="noopener noreferrer"
									className="btn btn-danger px-3 py-2"
                >
                  Prendre rdv en ligne
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navigation;
