import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Coiffure from "./Coiffure";
import Contact from "./Contact";
import Footer from "./Footer";
import Header from "./Header";
import Navigation from "./Navigation";
import Onglerie from "./Onglerie";
import Values from "./Values";
import "./css/App.css";

class App extends React.Component {
  componentDidMount() {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "https://api.countapi.xyz/hit/borealyss.com/visits");
    xhr.responseType = "json";
    xhr.send();
	}

  render() {
    return (
      <>
        <Router>
          <Navigation />
          <Header />
          <div>
            <Switch>
              <Route path="/coiffure">
                <Coiffure />
              </Route>
              <Route path="/onglerie">
                <Onglerie />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/">
                <Values />
              </Route>
            </Switch>
          </div>
        </Router>
        <Footer />
      </>
    );
  }
}

export default App;
