import React from "react";
import logo from "./assets/logo.png";

function Header() {
  return (
    <section className="header-image text-white">
      <div className="container p-3">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-10 text-center col-sm-12 m-4">
						<br />
            <a href="/"><img src={logo} alt="logo" width="80%" /></a>
						<br /><br /><br /><br />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
